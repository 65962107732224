import React from "react";

import Landing from "./Landing";

function HubspotBaseLanding({ descriptionContent, benefitsList }) {
  const titleImageSrc = "images/second-header-2.png";
  const videoThumbnailSrc = "/images/hubspot/video-thumbnail-with-animation.gif";
  const whiteListButtonText = "Sign Up for the Beta Now";

  return (
    <Landing
      descriptionContent={descriptionContent}
      benefitsList={benefitsList}
      titleImageSrc={titleImageSrc}
      videoThumbnailSrc={videoThumbnailSrc}
      whiteListButtonText={whiteListButtonText}
    />
  );
}

export default HubspotBaseLanding;
